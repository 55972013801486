import { Injectable, NgZone } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpErrorResponse,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';
import { catchError, takeUntil } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { TranslationService } from '../services/translation.service';
import { HttpcancelService } from './httpcancel.service';

@Injectable()
export class HTTPInterceptor implements HttpInterceptor {

    alert: any;

    constructor(private router: Router, private route: ActivatedRoute, private _ngZone: NgZone, public _commonService: CommonService, private _translationService: TranslationService,
        private httpCancelService: HttpcancelService) {
        router.events.subscribe(event => {
            // An event triggered at the end of the activation part of the Resolve phase of routing.
            if (event instanceof ActivationEnd) {
                // Cancel pending calls
                this.httpCancelService.cancelPendingRequests();
            }
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        const clonedReq = (request.url !== 'https://www.procit.com/?feed=faqfeed') ? request.clone({
          // Prevent caching in IE, in particular IE11.
          // See: https://support.microsoft.com/en-us/help/234067/how-to-prevent-caching-in-internet-explorer
          setHeaders: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache'
          }
        }): request;

        return next.handle(clonedReq).pipe(catchError(err => {
            if(this.router.url.includes('/sso_landing') || this.router.url.includes('/sso_callback')) {
                let error = err.error;
                //if(error && 'UserExists' in error) {
                return throwError(error)
                // } else {
                //     this._commonService.presentAlert(this._translationService.translationData["label-attention"], err.error ? err.error.message ? err.error.message : err.error : err);
                //     this.router.navigate(['/login']);
                //     return throwError(err);

                // }

            }
            else if(this.router.url.includes('/documentgenerator') && this.router.url.includes('/upload')) {
                if(err.status === 500) {
                    if (err.error.message) {
                        this._commonService.presentAlert("", err.error.message);
                    } else {
                        this._commonService.presentAlert("", this._translationService.translationData["label-attention"]);
                    }
                    return throwError(err)
                } else {
                    return throwError(err);
                }
            } else {

                if (err.status === 0) {
                  let tempError = (err.error && err.error.message ) ?  err.error.message : (err.message ? err.message : 'unknown error');
                  console.error('Error with status code 0:' + tempError );
                  return throwError(tempError);
                }
                if (err.status === 401 || err.status === 440 ) {
                    if(err.statusText == 'Too Many Requests'){
                        return throwError(err);
                    }else {
                        this._commonService.closeRightDrawer.next('directClosed');
                        if (err.error.message) {
                            if(err.status === 440 && localStorage.sessionStartTime) {
                                let currentTime = new Date().getHours();
                                if(currentTime >= localStorage.sessionStartTime) {
                                    if((currentTime - localStorage.sessionStartTime) <= 3) {
                                        if(this._commonService.overlapAlertMessage) {
                                          this._commonService.presentAlert('', err.error.message);
                                          this._commonService.overlapAlertMessage = false;
                                        }
                                    }
                                } else if(currentTime < localStorage.sessionStartTime) {
                                    if((localStorage.sessionStartTime - currentTime) <= 3) {
                                        if(this._commonService.overlapAlertMessage) {
                                          this._commonService.presentAlert("", err.error.message);
                                          this._commonService.overlapAlertMessage = false;
                                        }
                                    }
                                }
                            } else {
                                if(this._commonService.overlapAlertMessage) {
                                  this._commonService.presentAlert("", err.error.message);
                                  this._commonService.overlapAlertMessage = false;
                                }
                            }



                        } else {
                            if(err.status === 440 && localStorage.sessionStartTime) {
                                    // let timeNow = new Date().getHours();
                                    // if((timeNow - localStorage.sessionStartTime) <= 3) {
                                    //     this._commonService.presentAlert("", this._translationService.translationData["label-attention"]);
                                    // }
                                    let currentTime = new Date().getHours();
                                    if(currentTime >= localStorage.sessionStartTime) {
                                        if((currentTime - localStorage.sessionStartTime) <= 3) {
                                            this._commonService.presentAlert("", this._translationService.translationData["label-attention"]);
                                        }
                                    } else if(currentTime < localStorage.sessionStartTime) {
                                        if((localStorage.sessionStartTime - currentTime) <= 3) {
                                            this._commonService.presentAlert("", this._translationService.translationData["label-attention"]);

                                        }
                                    }
                            } else {
                                this._commonService.presentAlert("", this._translationService.translationData["label-attention"]);
                            }

                        }
                        if (this.router.url != '/loginverification'){
                            this._commonService.clearData();
                            this._ngZone.run(() => {
                                this.router.navigate(['/login'], { replaceUrl: true });
                            })
                        }
                        let error = err.message || err.statusText;
                        return throwError(error)
                    }

                }
                if (err.status === 413) {
                    if (err.error.message) {
                        this._commonService.presentAlert("", err.error.message);
                    } else {
                        this._commonService.presentAlert("", this._translationService.translationData["label-attention"]);
                    }
                    let error = err;
                    return throwError(error)
                }

                if (err.status === 429) {
                    if (err.error.message) {
                        this._commonService.presentAlert("", err.error.message);
                    } else {
                        this._commonService.presentAlert("", this._translationService.translationData["label-attention"]);
                    }
                    let error = err;
                    return throwError(error)
                }

                if (err.status === 422) {
                    if (err.error.message) {
                        this._commonService.presentAlert("", err.error.message);
                    } else {
                        this._commonService.presentAlert("", this._translationService.translationData["label-attention"]);
                    }
                    let error = err.message || err.statusText;
                    return throwError(err)
                }

                if (err.status === 404) {
                    let url = this.router.url.split('/');
                    // Check if route state url matches the folder entry URL, if yes => present routable alert
                    if (url[1] == "objects" && url[3] == "objectfolders" && url[5] == "folderentries"){
                        this._commonService.presentRoutingAlert(this._translationService.translationData["label-attention"], err.error ? err.error.message ? err.error.message : err.error : err,err.status);
                    } else{
                        this._commonService.presentAlert(this._translationService.translationData["label-attention"], err.error ? err.error.message ? err.error.message : this._translationService.translationData["label-itemUnavailable"] : err);
                    }
                    let error = err;
                    return throwError(error)
                }
                if (err.status === 405 || err.status === 412 || err.status === 409 || err.status === 424 || err.status === 415 || err.status === 409 || err.status === 403 || err.status === 402 || err.status === 408) {
                    if(err.status == 402 && (this.router.url == "/documentgenerator" || this.router.url.includes('/documentgenerator/wizard/'))){
                        this._commonService.presentRoutingAlert('',err.error ? err.error.message ? err.error.message : err.error : err,err.status);
                    } else if (err.status == 409 && this.router.url.includes("/EmaillinkAuthentication")) {
                        //just show page invalid page and no alert.
                        this.router.navigate(['/login-invalid']);
                    } else if (err.status == 403) {
                        if (this.router.url.includes('/data/customers') && request.method == 'DELETE'){
                            this._commonService.presentAlert("", err.error.message);
                        }else {
                            if(this._commonService.userRoles.length === 1 && this._commonService.userRoles.includes('UserRole_Invoicer')){
                                this.router.navigate(['/invoicing/invoices'], { replaceUrl: true });
                            }
                            else{
                                this.router.navigate(['/reservations'], { replaceUrl: true });
                            }
                            this._commonService.presentAlert("", err.error.message);
                        }

                    }
                    else if (err.error.message) {
                        this._commonService.presentAlert("", err.error.message);
                    } else {
                        this._commonService.presentAlert("", this._translationService.translationData["label-attention"]);
                    }

                    let error = err.message || err.statusText;
                    return throwError(error)
                }

                if (err.status >= 500 && err.status < 600) {
                    if (err.error.message) {
                        this._commonService.presentAlert("", err.error.message);
                    } else {
                        this._commonService.presentAlert("", this._translationService.translationData["label-attention"]);
                    }
                    let error = err.message || err.statusText;
                    return throwError(err)
                }

                if (err.status === 400){

                    if (this.router.url.includes("/email/reservations/")){ 
                        this.router.navigate(['login']);
                    }
                    if(err.error.message == 'Bad Request!!'){
                        this._commonService.presentAlert("", this._translationService.translationData["Error-BadRequest"]);
                        let error = err.message || err.statusText;
                        return throwError(error);
                    }
                    if(err.error.message == 'Please verify email'){
                        this._commonService.showSendAgainbutton = true;
                        this._commonService.presentAlert(this._translationService.translationData["label-attention"], this._translationService.translationData["Error-checkEmail"]);
                        let error = err.message || err.statusText;
                        return throwError(error);
                    }

                }

                var errorMessage = (err.error && err.error.message ) ?  err.error.message : (err.message ? err.message : 'unknown error');

                this._commonService.presentAlert(this._translationService.translationData["label-attention"], errorMessage);
                let error = err.message || err.statusText;
                return throwError(error);
            }
        // }), takeUntil(this.httpCancelService.onCancelPendingRequests()))
        }))
    }

}
